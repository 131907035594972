import Firmware from '../../models/Firmware'
import FirmwareLatest from '../../models/FirmwareLatest'
import Page from '../../models/pagination/Page'

export enum FirmwareActionTypes {
  DOWNLOAD_REQUEST = '@@firmware/DOWNLOAD_FIRMWARE_REQUEST',
  DOWNLOAD_SUCCESS = '@@firmware/DOWNLOAD_FIRMWARE_SUCCESS',
  FETCH_ACTIVE_FIRMWARE_VERSIONS_REQUEST = '@@firmware/FETCH_FIRMWARE_VERSIONS_REQUEST',
  FETCH_ACTIVE_FIRMWARE_VERSIONS_SUCCESS = '@@firmware/FETCH_ACTIVE_FIRMWARE_VERSIONS_SUCCESS',
  FETCH_LATEST_REQUEST = '@@firmware/FETCH_LATEST_FIRMWARE_REQUEST',
  FETCH_LATEST_SUCCESS = '@@firmware/FETCH_LATEST_FIRMWARE_SUCCESS',
  UPDATE_REQUEST = '@@firmware/UPDATE_FIRMWARE_REQUEST',
  UPDATE_SUCCESS = '@@firmware/UPDATE_FIRMWARE_SUCCESS',
  ERROR = '@@firmware/FIRMWARE_ERROR',
}

export interface FirmwareState {
  readonly activeFirmwareVersionsPage: Page<Firmware>
  readonly latestFirmware: FirmwareLatest
  readonly loading: boolean
  readonly errors?: string
}
