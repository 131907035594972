import Device from '../../models/Device'
import Page from '../../models/pagination/Page'

export enum DeviceActionTypes {
  FETCH_REQUEST = '@@device/FETCH_DEVICE_REQUEST',
  FETCH_SUCCESS = '@@device/FETCH_DEVICE_SUCCESS',
  UPDATE_REQUEST = '@@device/UPDATE_DEVICE_REQUEST',
  UPDATE_SUCCESS = '@@device/UPDATE_DEVICE_SUCCESS',
  ERROR = '@@device/DEVICE_ERROR',
}

export interface DeviceState {
  readonly loading: boolean
  readonly data: Page<Device>
  readonly errors?: string
}
