import { ActionCreator, Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { UserActionTypes } from './types'
import { SnackBarActionTypes, SeverityType } from '../snackbar/types'
import { ApplicationState } from '..'
import { httpGetRequest } from '../../utils/HttpUtil'
import { userService, USER_DETAILS_KEY } from '../../services/user-service'
import history from '../../utils/history'

const API_URL = `${process.env.REACT_APP_API_URL}`

export const showSetupGuide: ActionCreator<Action<string>> = (
  dispatch: Dispatch
) => {
  return dispatch({ type: UserActionTypes.SHOW_SETUP })
}

export const dismissSetupGuide: ActionCreator<Action<string>> = (
  dispatch: Dispatch
) => {
  return dispatch({ type: UserActionTypes.DISMISS_SETUP })
}

export const login: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = ({ username, password }) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: UserActionTypes.FETCH_REQUEST, username })

    try {
      const user = await userService.login(username, password)
      dispatch({ type: UserActionTypes.FETCH_SUCCESS, user })
      localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(user))
      history.push('/portal')
    } catch (error) {
      dispatch({ type: UserActionTypes.FETCH_ERROR, error })
      dispatch({
        type: SnackBarActionTypes.OPEN,
        message: error,
        severity: SeverityType.ERROR,
      })
    }
  }
}

export const acceptedTOS: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: UserActionTypes.FETCH_REQUEST })

    try {
      await httpGetRequest(`${API_URL}/api/v1/user/tos`)
      dispatch({ type: UserActionTypes.DISMISS_SETUP })
      dispatch({ type: UserActionTypes.FETCH_SUCCESS })
    } catch (error) {
      dispatch({ type: UserActionTypes.FETCH_ERROR })
    }
  }
}
