import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ApplicationState } from '../../store/index'
import { SeverityType } from '../../store/snackbar/types'
import { openSnackBar } from '../../store/snackbar/action'
import { ConsumableState } from '../../store/consumable/types'
import { restoreConsumableById } from '../../store/consumable/action'

interface Props {
  consumableId: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      color: theme.palette.getContrastText(theme.palette.error.main),
      backgroundColor: theme.palette.error.main,
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    icon: {
      color: '#fff',
    },
  })
)

export default function RestoreInventoryItemDialog(props: Props): JSX.Element {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const consumableState = useSelector<ApplicationState, ConsumableState>(
    (state) => state.consumables
  )

  const restoreConsumable = (consumableId: number) => {
    dispatch(restoreConsumableById(consumableId))

    if (consumableState.errors) {
      openSnackBar(
        dispatch,
        `Error attempting to restore consumable: ${consumableId}`,
        SeverityType.ERROR
      )
    } else {
      openSnackBar(
        dispatch,
        `Successfully restored consumable`,
        SeverityType.SUCCESS
      )
    }
  }

  const handleConfirm = (id: number) => {
    restoreConsumable(id)
    setOpen(false)
  }

  const handleCancel = () => {
    openSnackBar(dispatch, `Restoration canceled`, SeverityType.INFO)
    setOpen(false)
  }

  const modalOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Avatar variant="rounded" className={classes.avatar}>
        <IconButton onClick={modalOpen}>
          <RestoreFromTrashIcon className={classes.icon} fontSize="small" />
        </IconButton>
      </Avatar>

      <div>
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="update-device-form-dialog"
        >
          <DialogTitle id="form-dialog-title">
            Confirm Restored Item
          </DialogTitle>
          <DialogContentText>
            Are you sure you want to restore this item?
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleCancel}>No</Button>
            <Button onClick={() => handleConfirm(props.consumableId)}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
