import React from 'react'
import StompClient from 'react-stomp-client'

import { useDispatch } from 'react-redux'
import { fetchConsumables } from '../../store/consumable/action'

const WS_URL = `${process.env.REACT_APP_WS_URL}`

export default function NewConsumableWebSocketListener(): JSX.Element {
  const dispatch = useDispatch()

  const handleNewReadingMessage = () => {
    dispatch(fetchConsumables(undefined, undefined, 'lastModified,desc'))
  }

  return (
    <>
      <StompClient
        endpoint={`${WS_URL}/rts-events`}
        topic="topic/new-reading"
        onMessage={handleNewReadingMessage}
      >
        <div />
      </StompClient>
    </>
  )
}
