import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { closeSnackBar } from '../../store/snackbar/action'
import { SnackBarState } from '../../store/snackbar/types'
import { ApplicationState } from '../../store/index'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const TIMEOUT = 20000

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const CustomSnackbar: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const snackbar = useSelector<ApplicationState, SnackBarState>(
    (state) => state.snackbar
  )
  const classes = useStyles()

  const handleClose = () => {
    closeSnackBar(dispatch)
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={TIMEOUT}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CustomSnackbar
