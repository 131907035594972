import { ActionCreator, AnyAction, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { FirmwareActionTypes } from './types'
import { ApplicationState } from '../index'
import {
  httpPostFormFirmwareRequest,
  httpGetRequest,
} from '../../utils/HttpUtil'
import ApiResponseError from '../../exceptions/ApiResponseError'
import FirmwareLatest from '../../models/FirmwareLatest'
import { SnackBarActionTypes, SeverityType } from '../snackbar/types'
import Firmware from '../../models/Firmware'
import Page from '../../models/pagination/Page'

const API_URL = `${process.env.REACT_APP_API_URL}`

const handleApiError = (error: unknown, actionType: FirmwareActionTypes) => {
  const apiError = error as ApiResponseError
  return {
    type: actionType,
    payload: JSON.stringify(apiError),
  }
}

// Define a common type for all thunks in this file
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ApplicationState,
  string,
  AnyAction
>

export const downloadFirmware: ActionCreator<AppThunk> = (fileName: string) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: FirmwareActionTypes.DOWNLOAD_REQUEST })

    try {
      await httpGetRequest(`${API_URL}/api/v1/firmware/download/${fileName}`)
      return dispatch({ type: FirmwareActionTypes.DOWNLOAD_SUCCESS })
    } catch (error) {
      return dispatch(handleApiError(error, FirmwareActionTypes.ERROR))
    }
  }
}

export const fetchActiveFirmwareVersions: ActionCreator<AppThunk> = (
  page = 0,
  size = 5,
  sort = ''
) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({
      type: FirmwareActionTypes.FETCH_ACTIVE_FIRMWARE_VERSIONS_REQUEST,
    })

    try {
      const success = await httpGetRequest<Page<Firmware>>(
        `${API_URL}/api/v1/firmware?page=${page}&size=${size}&sort=${sort}`
      )
      return dispatch({
        type: FirmwareActionTypes.FETCH_ACTIVE_FIRMWARE_VERSIONS_SUCCESS,
        payload: success.data,
      })
    } catch (error) {
      return dispatch(handleApiError(error, FirmwareActionTypes.ERROR))
    }
  }
}

export const fetchLatestFirmware: ActionCreator<AppThunk> = () => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: FirmwareActionTypes.FETCH_LATEST_REQUEST })

    try {
      const success = await httpGetRequest<FirmwareLatest>(
        `${API_URL}/api/v1/firmware/latest`
      )
      return dispatch({
        type: FirmwareActionTypes.FETCH_LATEST_SUCCESS,
        payload: success?.data,
      })
    } catch (error) {
      return dispatch(handleApiError(error, FirmwareActionTypes.ERROR))
    }
  }
}

export const updateFirmware: ActionCreator<AppThunk> = (
  version: string,
  file: File
) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: FirmwareActionTypes.UPDATE_REQUEST })

    try {
      await httpPostFormFirmwareRequest(
        `${API_URL}/api/v1/firmware/upload`,
        version,
        file
      )

      dispatch({
        type: SnackBarActionTypes.OPEN,
        message: 'Successfully uploaded new firmware!',
        severity: SeverityType.SUCCESS,
      })
      dispatch({
        type: FirmwareActionTypes.FETCH_LATEST_SUCCESS,
        payload: { version, s3BucketUrl: '' },
      })

      return dispatch({
        type: FirmwareActionTypes.UPDATE_SUCCESS,
      })
    } catch (e) {
      const error = e as ApiResponseError

      dispatch({
        type: SnackBarActionTypes.OPEN,
        message: 'Failed to add new firmware!',
        severity: SeverityType.ERROR,
      })

      return dispatch({
        type: FirmwareActionTypes.ERROR,
        errors: JSON.stringify(error),
      })
    }
  }
}
