import { Reducer } from 'redux'
import { FirmwareActionTypes, FirmwareState } from './types'
import Page from '../../models/pagination/Page'
import Firmware from '../../models/Firmware'
import FirmwareLatest from '../../models/FirmwareLatest'

export const initialState: FirmwareState = {
  activeFirmwareVersionsPage: {} as Page<Firmware>,
  latestFirmware: {} as FirmwareLatest,
  errors: undefined,
  loading: false,
}

const reducer: Reducer<FirmwareState> = (state = initialState, action) => {
  switch (action.type) {
    case FirmwareActionTypes.DOWNLOAD_REQUEST: {
      return { ...state, loading: true }
    }
    case FirmwareActionTypes.DOWNLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeFirmwareVersionsPage: action.payload,
        errors: undefined,
      }
    }
    case FirmwareActionTypes.FETCH_ACTIVE_FIRMWARE_VERSIONS_REQUEST: {
      return { ...state, loading: true }
    }
    case FirmwareActionTypes.FETCH_ACTIVE_FIRMWARE_VERSIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeFirmwareVersionsPage: action.payload,
        errors: undefined,
      }
    }
    case FirmwareActionTypes.FETCH_LATEST_REQUEST: {
      return { ...state, loading: true }
    }
    case FirmwareActionTypes.FETCH_LATEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        latestFirmware: action.payload,
        errors: undefined,
      }
    }
    case FirmwareActionTypes.UPDATE_REQUEST: {
      return { ...state, loading: true }
    }
    case FirmwareActionTypes.UPDATE_SUCCESS: {
      return { ...state, loading: false }
    }
    case FirmwareActionTypes.ERROR: {
      console.error(action.errors)
      return { ...state, loading: false, errors: action.errors }
    }
    default: {
      return state
    }
  }
}

export { reducer as FirmwareReducer }
