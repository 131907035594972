import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import HttpStatus from 'http-status-codes'
import ApiResponseError from '../exceptions/ApiResponseError'
import AuthorizationService from '../services/auth-service'
import ApiAxiosResponseError from '../exceptions/ApiAxiosResponseError'
import { userService } from '../services/user-service'

function handleErrorResponse(error: ApiAxiosResponseError) {
  if (error.response.status === 401) {
    userService.logout()
  }

  return Promise.reject(error.response)
}

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const url = config.url ? config.url : ''

    if (url.includes('/account/')) return config

    const newConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${
          AuthorizationService.getCurrentUserDetails().access_token
        }`,
      },
    }

    return newConfig
  },
  (err: Error) => {
    console.error('error in getting ', err)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return handleErrorResponse(error)
  }
)

const httpOkStatus = [
  HttpStatus.OK,
  HttpStatus.CREATED,
  HttpStatus.ACCEPTED,
  HttpStatus.NO_CONTENT,
]

const httpGetRequest = async <T1>(path: string): Promise<AxiosResponse<T1>> => {
  const response = await axios(path, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  if (!httpOkStatus.includes(response.status)) {
    throw new ApiAxiosResponseError('HTTP GET request failed.', response)
  }

  return response
}

const httpPostRequest = async <T1>(
  path: string,
  requestBody: T1
): Promise<AxiosResponse> => {
  const response = await axios(path, {
    method: 'POST',
    data: JSON.stringify(requestBody),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  if (!httpOkStatus.includes(response.status)) {
    throw new ApiAxiosResponseError('HTTP POST request failed.', response)
  }

  return response
}

const httpPostRequestWithoutBody = async (
  path: string
): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios(path, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  if (!httpOkStatus.includes(response.status)) {
    throw new ApiAxiosResponseError('HTTP POST request failed.', response)
  }

  return response
}

const httpPostFormCredentialsRequest = async (
  path: string,
  username: string,
  password: string
): Promise<Response> => {
  const formData = new FormData()
  formData.append('username', username)
  formData.append('password', password)

  const response: Response = await fetch(path, {
    method: 'POST',
    body: formData,
    headers: { Authorization: 'Basic c3N0LXBvcnRhbDpzc3QtbGFi' },
  })

  if (
    !httpOkStatus.includes(response.status) ||
    response.url.includes('error=true')
  ) {
    console.error('HTTP POST request failed', response)
    throw new ApiResponseError('HTTP POST request failed.', response)
  }

  return response
}

const httpPostFormFirmwareRequest = async (
  path: string,
  version: string,
  file: File
): Promise<AxiosResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('version', version)
  const response: AxiosResponse = await axios(path, {
    method: 'POST',
    data: formData,
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    },
  })

  if (!httpOkStatus.includes(response.status)) {
    throw new ApiAxiosResponseError('HTTP POST request failed.', response)
  }

  return response
}

const httpPutRequest = async <T1>(
  path: string,
  requestBody: T1
): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios(path, {
    method: 'PUT',
    data: JSON.stringify(requestBody),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  if (!httpOkStatus.includes(response.status)) {
    throw new ApiAxiosResponseError('HTTP PUT request failed.', response)
  }

  return response
}

const httpDeleteRequest = async <T1>(
  path: string
): Promise<AxiosResponse<T1>> => {
  const response: AxiosResponse = await axios(path, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  if (!httpOkStatus.includes(response.status)) {
    throw new ApiAxiosResponseError('HTTP DELETE request failed.', response)
  }

  return response
}

export {
  httpGetRequest,
  httpPostRequest,
  httpPostFormCredentialsRequest,
  httpPostFormFirmwareRequest,
  httpPostRequestWithoutBody,
  httpPutRequest,
  httpDeleteRequest,
}
