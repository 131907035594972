import React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import Consumable from 'models/Consumable'
import InventoryDepletedTable from './inventory-depleted-table.component'

import { ApplicationState } from '../../../store'
import { fetchDepletedConsumables as fetchDepletedConsumablesAction } from '../../../store/consumable/action'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

type MyRootState = ApplicationState
type MyExtraArg = Consumable
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, AnyAction>

class InventoryItems extends React.Component<Props> {
  componentDidMount = () => {
    const { fetchDepletedConsumables } = this.props
    fetchDepletedConsumables()
  }

  render() {
    const { errors, depletedConsumablesPage, fetchDepletedConsumables } =
      this.props
    if (!depletedConsumablesPage.content) {
      return (
        <div>
          <LinearProgress />
        </div>
      )
    }

    if (errors) {
      // TODO: show an error screen
    }

    return (
      <div>
        <InventoryDepletedTable
          page={depletedConsumablesPage}
          fetchDepletedConsumables={fetchDepletedConsumables}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ consumables }: ApplicationState) => ({
  depletedConsumablesPage: consumables.depletedConsumablesPage,
  errors: consumables.errors,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => {
  return {
    fetchDepletedConsumables: (page?: number, size?: number) =>
      dispatch(fetchDepletedConsumablesAction(page, size)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryItems)
