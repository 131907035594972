import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import SignUp from './signup.component'
import { SeverityType } from '../../store/snackbar/types'
import { openSnackBar as openSnackBarAction } from '../../store/snackbar/action'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openSnackBar: (message: string, severity: SeverityType) => {
    openSnackBarAction(dispatch, message, severity)
  },
})

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = DispatchProps

interface State {
  message: string
  severity: SeverityType
  requestSent: boolean
}

class SignInContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      message: '',
      severity: SeverityType.SUCCESS,
      requestSent: false,
    }
  }

  componentDidUpdate() {
    const { openSnackBar } = this.props
    const { requestSent, message, severity } = this.state
    if (requestSent) {
      openSnackBar(message, severity)
    }
  }

  requestHasBeenMade = (message: string, severity: SeverityType) => {
    this.setState({ message, requestSent: true, severity })
  }

  render() {
    return <SignUp callBack={this.requestHasBeenMade} />
  }
}

export default connect(null, mapDispatchToProps)(SignInContainer)
