import React, { useState } from 'react'
import {
  Toolbar,
  Table,
  TableContainer,
  Typography,
  Grid,
  TableFooter,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SeverityType } from 'store/snackbar/types'
import InventoryHistoryDialog from './inventory-history-dialog.component'
import NewInventoryItemModal from './inventory-item-new-dialog.component'
import Consumable from '../../models/Consumable'
import Page from '../../models/pagination/Page'
import { httpGetRequest } from '../../utils/HttpUtil'
import Reading from '../../models/Reading'

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 10%',
  },
}))

const SearchCategory = {
  ITEM_NAME: 'itemName',
  CAS_NUMBER: 'casNumber',
  PRODUCT_NUMBER: 'productNumber',
}

interface Props {
  page: Page<Consumable>
  openSnackBar: (message: string, severity: SeverityType) => void
  fetchActiveConsumablesPage: (
    page?: number,
    size?: number,
    queryName?: string,
    queryParameter?: string
  ) => void
}

const API_URL: string = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : ''

export default function InventoryItemTable(props: Props): JSX.Element {
  const classes = useStyles()
  const { page, openSnackBar } = props
  const [, setIsMountedVal] = useState(false)
  const [show, setShow] = useState(false)
  const [readings, setReadings] = useState<Reading[]>([])
  const [consumable, setConsumable] = useState<Consumable>()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchCategory, setSearchCategory] = useState(SearchCategory.ITEM_NAME)

  const fetchReadingsForConsumable = async (tagId: string) => {
    try {
      const response = await httpGetRequest<Reading[]>(
        `${API_URL}/api/v1/readings/${tagId}`
      )
      setReadings(response.data.length > 0 ? response.data : [])
    } catch (e) {
      console.error(e)
      setReadings([])
    }
  }

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value)
  }

  const handleUnmountedVal = () => setIsMountedVal(false)

  const handleClose = () => setShow(false)

  const handleClick = (consumableItem: Consumable) => {
    if (consumableItem.tag) {
      fetchReadingsForConsumable(consumableItem.tag.tagId)
    } else {
      setReadings([])
    }
    setConsumable(consumableItem)
    setShow(true)
  }

  const handleSearchEnterPress = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      props.fetchActiveConsumablesPage(0, 10, searchCategory, searchTerm)
    }
  }

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCategory(event.target.value)
    setSearchTerm('')
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    props.fetchActiveConsumablesPage(newPage, props.page.size)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.fetchActiveConsumablesPage(0, Number(event.target.value))
  }

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography
          component="h2"
          variant="inherit"
          color="textPrimary"
          gutterBottom
        >
          Inventory Items
        </Typography>
        <TableContainer component={Paper}>
          <Toolbar>
            <Grid
              container
              spacing={1}
              alignItems="flex-start"
              justify="space-between"
            >
              <Grid item xs={7}>
                <TextField
                  select
                  label="Search Category"
                  variant="outlined"
                  margin="dense"
                  defaultValue="ChemicalName"
                  value={searchCategory}
                  onChange={handleCategoryChange}
                >
                  <MenuItem value={SearchCategory.PRODUCT_NUMBER}>
                    Product Number
                  </MenuItem>
                  <MenuItem value={SearchCategory.ITEM_NAME}>
                    Item Name
                  </MenuItem>
                  <MenuItem value={SearchCategory.CAS_NUMBER}>
                    CAS Number
                  </MenuItem>
                </TextField>
                <TextField
                  label="Search"
                  variant="outlined"
                  margin="dense"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchEnterPress}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={3}>
                <NewInventoryItemModal openSnackBar={openSnackBar} />
              </Grid>
            </Grid>
          </Toolbar>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item Name</TableCell>
                <TableCell>Product Number</TableCell>
                <TableCell>CAS Number</TableCell>
                <TableCell>Lot Number</TableCell>
                <TableCell>Storage Location</TableCell>
                <TableCell>Net Weight</TableCell>
                <TableCell>Units</TableCell>
                <TableCell>Expiration</TableCell>
                <TableCell>Last Modified</TableCell>
                <TableCell>TagId</TableCell>
                {/* <TableCell>History</TableCell>
                <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {page.content.map((activeConsumable: Consumable) => (
                <TableRow
                  hover
                  key={activeConsumable.id}
                  onClick={() => handleClick(activeConsumable)}
                >
                  <TableCell>{activeConsumable.itemName}</TableCell>
                  <TableCell>{activeConsumable.productNumber}</TableCell>
                  <TableCell>{activeConsumable.casNumber}</TableCell>
                  <TableCell>{activeConsumable.lotNumber}</TableCell>
                  <TableCell>{activeConsumable.location}</TableCell>
                  <TableCell>{activeConsumable.netWeight}</TableCell>
                  <TableCell>{activeConsumable.unit}</TableCell>
                  <TableCell>{activeConsumable.expiration}</TableCell>
                  <TableCell>{activeConsumable.lastModified}</TableCell>
                  <TableCell>{activeConsumable.tag?.tagId}</TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  colSpan={10}
                  count={page.totalElements}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page.number}
                  rowsPerPage={page.size}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <InventoryHistoryDialog
          show={show}
          handleClose={handleClose}
          handleUnmountedVal={handleUnmountedVal}
          readings={readings}
          consumable={consumable}
        />
      </Grid>
    </Grid>
  )
}
