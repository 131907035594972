import React from 'react'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { fetchLab, updateDefaultLab } from 'store/lab/action'
import Lab from '../../models/Lab'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

interface Props {
  lab: Lab | undefined
  labs: Lab[] | undefined
}

const LabMenu: React.FunctionComponent<Props> = (props: Props) => {
  const { lab, labs } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const dispatch = useDispatch()

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLabSwitch = async (labId: number) => {
    handleClose()
    if (labId !== lab?.labId) {
      await dispatch(updateDefaultLab(labId)) // Update default lab
      await dispatch(fetchLab()) // Fetch new lab data (ensure this action is defined in your actions)
    }
  }

  const hasMultipleLabs = labs && labs.length > 1

  return (
    <div>
      <Button
        aria-controls="lab-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={hasMultipleLabs ? handleOpen : undefined} // Disable the button if only one lab
      >
        {lab ? lab.name : 'Lab Not Found'}
      </Button>
      {hasMultipleLabs && (
        <StyledMenu
          id="lab-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {labs.map((labItem) => (
            <MenuItem
              key={labItem.labId}
              onClick={() => handleLabSwitch(labItem.labId)}
              selected={labItem.labId === lab?.labId}
            >
              {labItem.name}
            </MenuItem>
          ))}
        </StyledMenu>
      )}
    </div>
  )
}

export default LabMenu
