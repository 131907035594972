import JwtResponse from '../../models/JwtResponse'

export enum UserActionTypes {
  SHOW_SETUP = '@@user/SHOW_USER_SETUP',
  DISMISS_SETUP = '@@user/DISMISS_USER_SETUP',
  FETCH_REQUEST = '@@user/FETCH_USER_REQUEST',
  FETCH_SUCCESS = '@@user/FETCH_USER_SUCCESS',
  FETCH_ERROR = '@@user/FETCH_USER_ERROR',
}

export interface UserState {
  readonly showWelcome: boolean
  readonly loading?: boolean
  readonly jwtResponse?: JwtResponse
  readonly errors?: string
  readonly loggedIn: boolean
  readonly hasAdminRole: boolean
}
