import React from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function NotFound(): JSX.Element {
  return (
    <div className="d-flex justify-content-center align-items-center position-relative full-height">
      <div className="top-right links">
        <Link to="/user/signin">SignIn</Link>
      </div>

      <div className="content">
        <div className="m-b-md">
          <Image
            src={`${process.env.PUBLIC_URL}/flat-transparent-logo.png`}
            className="w-50 bg-white"
            thumbnail
          />
        </div>
        <h2>404: Not Found</h2>
      </div>
    </div>
  )
}

export default NotFound
