import React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ApplicationState } from '../../store'
import {
  fetchActiveFirmwareVersions as fetchActiveFirmwareVersionsAction,
  fetchLatestFirmware as fetchLatestFirmwareAction,
  updateFirmware,
} from '../../store/firmware/action'
import FirmwareTable from './firmware-table.component'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

type MyRootState = ApplicationState
type MyExtraArg = string
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, AnyAction>

class FirmwareContainer extends React.Component<Props> {
  componentDidMount = () => {
    const { fetchLatestFirmware, fetchActiveFirmwareVersions } = this.props
    fetchLatestFirmware()
    fetchActiveFirmwareVersions()
  }

  render() {
    const {
      errors,
      dispatchUpdateFirmware,
      activeFirmwareVersionsPage,
      latestFirmware,
    } = this.props
    if (!activeFirmwareVersionsPage.content) {
      return (
        <div>
          <LinearProgress />
        </div>
      )
    }

    if (errors) {
      // TODO: show an error screen
    }

    return (
      <div>
        <FirmwareTable
          page={activeFirmwareVersionsPage}
          fetchActiveFirmwareVersionsPage={fetchActiveFirmwareVersionsAction}
          fetchLatestFirmware={latestFirmware}
          updateFirmware={dispatchUpdateFirmware}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ firmware }: ApplicationState) => ({
  activeFirmwareVersionsPage: firmware.activeFirmwareVersionsPage,
  latestFirmware: firmware.latestFirmware,
  errors: firmware.errors,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => {
  return {
    dispatchUpdateFirmware: (version: string, file: File) =>
      dispatch(updateFirmware(version, file)),
    fetchActiveFirmwareVersions: (page?: number, size?: number) =>
      dispatch(fetchActiveFirmwareVersionsAction(page, size)),
    fetchLatestFirmware: () => dispatch(fetchLatestFirmwareAction()),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(FirmwareContainer)
