import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AuthenticationService from '../../services/auth-service'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.text.disabled,
    backgroundColor: 'transparent',
  },
}))

function Welcome(): JSX.Element {
  const classes = useStyles()
  const [isLoggedIn] = useState(AuthenticationService.isUserLoggedIn())
  return (
    <div className="d-flex justify-content-center align-items-center position-relative full-height">
      <div className="top-right links">
        {!isLoggedIn && (
          <Button className={classes.button} component={Link} to="/user/signin">
            Sign In
          </Button>
        )}
      </div>

      <div className="content">
        <div className="m-b-md">
          <Image
            src={`${process.env.PUBLIC_URL}/flat-transparent-logo.png`}
            className="w-50"
            thumbnail
          />
        </div>
      </div>
    </div>
  )
}

export default Welcome
