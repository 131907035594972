import { ActionCreator, Action, Dispatch } from 'redux'
import { SnackBarActionTypes, SeverityType } from './types'

export const openSnackBar: ActionCreator<Action<string>> = (
  dispatch: Dispatch,
  message: string,
  severity: SeverityType
) => {
  return dispatch({
    type: SnackBarActionTypes.OPEN,
    message,
    severity,
  })
}

export const closeSnackBar: ActionCreator<Action<string>> = (
  dispatch: Dispatch
) => {
  return dispatch({ type: SnackBarActionTypes.CLOSE })
}
