import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import CddIntegrationContainer from 'components/integrations/cdd/cdd-api-integration'
import InventoryItems from '../inventory-items'
import InventoryItemUpdate from '../inventory-items/inventory-item-update.component'
import ManageLabSpaceContainer from '../manage-lab'
import InventoryDepletedContainer from '../inventory-items/depleted-items'
import FirmwareContainer from '../firmware'
import NotFoundPortal from '../errors/404.portal.component'

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route
        exact
        history={useHistory}
        path="/portal"
        render={() => <div> Home Page</div>}
      />
      <Route
        exact
        history={useHistory}
        path="/portal/admin/firmware"
        component={FirmwareContainer}
      />
      <Route
        exact
        history={useHistory}
        path="/portal/inventory-items/depleted-consumables"
        component={InventoryDepletedContainer}
      />
      <Route
        exact
        history={useHistory}
        path="/portal/inventory-items/:id"
        component={InventoryItemUpdate}
      />
      <Route
        exact
        history={useHistory}
        path="/portal/inventory-items"
        component={InventoryItems}
      />
      <Route
        history={useHistory}
        path="/portal/billing"
        render={() => <div> Billing Page</div>}
      />
      <Route
        exact
        history={useHistory}
        path="/portal/account/settings"
        render={() => <div>Settings</div>}
      />
      <Route
        history={useHistory}
        path="/portal/account/settings/labspace"
        component={ManageLabSpaceContainer}
      />
      <Route
        history={useHistory}
        path="/portal/account/settings/integration/cdd"
        component={CddIntegrationContainer}
      />
      <Route history={useHistory} path="/" />
      <Route component={NotFoundPortal} />
    </Switch>
  )
}
