import Lab from '../../models/Lab'

export enum LabActionTypes {
  FETCH_LAB_REQUEST = '@@lab/FETCH_LAB_REQUEST',
  FETCH_LAB_SUCCESS = '@@lab/FETCH_LAB_SUCCESS',
  FETCH_LAB_ERROR = '@@lab/FETCH_LAB_ERROR',
  FETCH_LABS_REQUEST = '@@lab/FETCH_LABS_REQUEST',
  FETCH_LABS_SUCCESS = '@@lab/FETCH_LABS_SUCCESS',
  FETCH_LABS_ERROR = '@@lab/FETCH_LABS_ERROR',
  UPDATE_DEFAULT_LAB = '@@lab/UPDATE_DEFAULT_LAB',
}

export interface LabState {
  readonly loading: boolean
  readonly lab?: Lab
  readonly labs?: Lab[]
  readonly errors?: string
}
