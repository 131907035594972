import jsonWebTokenDecoder from '../utils/JWTDecoderUtil'
import JWTPayload from '../models/JWTPayload'

const OAUTH_TOKEN_URL = `${process.env.REACT_APP_OAUTH_URL}/oauth/token`
const OAUTH_AUTH_KEY = `${process.env.REACT_APP_OAUTH_AUTH_KEY}`
const ROLE_ADMIN = 'ROLE_ADMIN'
export const USER_DETAILS_KEY = 'USER_DETAILS'

async function login(username: string, password: string): Promise<Response> {
  const formData = new FormData()
  formData.append('username', username)
  formData.append('password', password)
  formData.append('grant_type', 'password')

  return fetch(OAUTH_TOKEN_URL, {
    method: 'POST',
    body: formData,
    headers: { Authorization: OAUTH_AUTH_KEY },
  }).then(async (res) => {
    const response = await res.json()
    if (res.status === 200) return response
    return Promise.reject(response.error_description)
  })
}

function logout(): void {
  // remove user from local storage to log user out
  localStorage.removeItem(USER_DETAILS_KEY)
  window.location.reload()
}

/**
 * Parses the access token to see if the ROLE_ADMIN claim is within the token.
 *
 * @param accessToken
 * @returns if ROLE_ADMIN is within the access token return true, else returns false.
 */
const isUserAnAdmin = (accessToken: string): boolean => {
  return jsonWebTokenDecoder<JWTPayload>(accessToken).authorities.includes(
    ROLE_ADMIN
  )
}

export const userService = {
  login,
  logout,
  isUserAnAdmin,
}
