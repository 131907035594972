import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { AxiosResponse } from 'axios'
import { httpPostRequestWithoutBody } from '../../utils/HttpUtil'
import { SeverityType } from '../../store/snackbar/types'
import FieldErrorMessage from '../../models/FieldErrorMessage'

const API_URL = `${process.env.REACT_APP_API_URL}`

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

interface Props {
  callBack: (message: string, severity: SeverityType) => void
}

interface ForgotPasswordInput {
  email: string
}

export default function ForgotPassword(props: Props): JSX.Element {
  const classes = useStyles()
  const { control, handleSubmit } = useForm<ForgotPasswordInput>()

  const onSubmit: SubmitHandler<ForgotPasswordInput> = async (data) => {
    const forgotPasswordInput = data as ForgotPasswordInput

    try {
      await httpPostRequestWithoutBody(
        `${API_URL}/api/v1/account/reset-password?email=${forgotPasswordInput.email}`
      )
      props.callBack(
        'If username exists, then an email has been sent to the user providing instructions on how to reset their password.',
        SeverityType.SUCCESS
      )
    } catch (e) {
      const error = e as AxiosResponse
      console.error(JSON.stringify(error.data))
      props.callBack(JSON.stringify(error.data), SeverityType.ERROR)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Forgot password?
        </Typography>
        <Typography component="p" variant="body1">
          Enter your email and we will send you a link to get back into your
          account.
        </Typography>
        <form className={classes.form}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: FieldErrorMessage.FIELD_REQUIRED_MESSAGE,
              pattern: {
                value: /^\S+@\S+$/,
                message: FieldErrorMessage.FIELD_NOT_EMAIL_MESSAGE,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                label="Email Address"
                id="email"
                onChange={onChange}
                value={value}
                {...(error && { error: true, helperText: error.message })}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
          <Grid container>
            <Grid item>
              <Link component={RouterLink} to="/user/signin" variant="body2">
                Return back to signin?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}
