import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import MailIcon from '@material-ui/icons/Mail'
import { SeverityType } from '../../store/snackbar/types'
import { openSnackBar } from '../../store/snackbar/action'
import { httpPostRequestWithoutBody } from '../../utils/HttpUtil'

const API_URL = `${process.env.REACT_APP_API_URL}`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
)

export default function SendLabInvitationDialog(): JSX.Element {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const sendLabInvitation = async (invite_email: string) => {
    try {
      await httpPostRequestWithoutBody(
        `${API_URL}/api/v1/admin/invite-user-to-lab-space?email=${invite_email}`
      )
    } catch (e) {
      openSnackBar(
        dispatch,
        `Failed to send invite to ${invite_email}`,
        SeverityType.ERROR
      )
    }
  }

  const handleSubmit = (invite_email: string) => {
    sendLabInvitation(invite_email)
    setOpen(false)
    openSnackBar(
      dispatch,
      `Lab invitation email has been sent!`,
      SeverityType.SUCCESS
    )
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<MailIcon />}
        onClick={handleClickOpen}
      >
        Invite User
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Invite User To Lab</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To invite a new user to this lab space, please enter the user email
            address here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={email}
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit(email)} color="primary">
            Send Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
