import { Reducer } from 'redux'
import { ConsumableActionTypes, ConsumableState } from './types'
import Page from '../../models/pagination/Page'
import Consumable from '../../models/Consumable'

export const initialState: ConsumableState = {
  activeConsumablesPage: {} as Page<Consumable>,
  depletedConsumablesPage: {} as Page<Consumable>,
  errors: undefined,
  loading: false,
}

const reducer: Reducer<ConsumableState> = (state = initialState, action) => {
  switch (action.type) {
    case ConsumableActionTypes.FETCH_ACTIVE_CONSUMABLE_REQUEST: {
      return { ...state, loading: true }
    }
    case ConsumableActionTypes.FETCH_ACTIVE_CONSUMABLE_SUCCESS: {
      return { ...state, loading: false, activeConsumablesPage: action.payload }
    }
    case ConsumableActionTypes.FETCH_DEPLETED_CONSUMABLE_REQUEST: {
      return { ...state, loading: true }
    }
    case ConsumableActionTypes.FETCH_DEPLETED_CONSUMABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        depletedConsumablesPage: action.payload,
      }
    }
    case ConsumableActionTypes.DEPLETE_CONSUMABLE_REQUEST: {
      return { ...state, loading: true }
    }
    case ConsumableActionTypes.DEPLETE_CONSUMABLE_REQUEST_SUCCESS: {
      // TODO: Immutable.js library
      const consumable = state.activeConsumablesPage.content.findIndex(
        (i_consumable) => i_consumable.id === action.id
      )
      const depletedConsumable = state.activeConsumablesPage.content.splice(
        consumable,
        1
      )
      const newDepletedConsumables =
        state.depletedConsumablesPage.content.concat(depletedConsumable)

      return {
        ...state,
        loading: false,
        activeConsumablesPage: state.activeConsumablesPage,
        depletedConsumablesPage: newDepletedConsumables,
      }
    }
    case ConsumableActionTypes.DEPLETE_CONSUMABLE_REQUEST_ERROR: {
      console.error(action.errors)
      return { ...state, loading: false, errors: action.errors }
    }
    default: {
      return state
    }
  }
}

export { reducer as ConsumableReducer }
