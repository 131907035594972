import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import FirmwareLatest from '../../models/FirmwareLatest'

const styles = (theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      margin: theme.spacing(1),
    },
    icon: {
      color: '#fff',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })

interface Props extends WithStyles<typeof styles> {
  handleClose: () => void
  show: boolean
  firmwareLatest: FirmwareLatest
  updateFirmware: (version: string, file: File) => void
}

interface State {
  file: File | undefined
  fileSelected: boolean
  value: string
}

class FirmwareUpdateFormDialog extends React.Component<Props, State> {
  isMountedVal: boolean

  constructor(props: Props) {
    super(props)
    this.state = {
      file: undefined,
      fileSelected: false,
      value: 'patch',
    }
    this.isMountedVal = false
  }

  componentDidMount = () => {
    this.isMountedVal = true
  }

  componentWillUnmount = () => {
    this.isMountedVal = false
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      this.setState({ file: event.target.files[0] })
      this.setState({ fileSelected: true })
    }
  }

  handleVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement
    this.setState({ value })
  }

  handleClearState = () => {
    this.setState({ file: undefined })
    this.setState({ fileSelected: false })
    this.setState({ value: 'patch' })
  }

  handleFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    file: File | undefined,
    firmwareLatest: FirmwareLatest,
    value: string,
    updateFirmware: (version: string, file: File) => void
  ) => {
    event.preventDefault()

    const readyToSubmitFile = file

    if (
      firmwareLatest.version !== undefined &&
      readyToSubmitFile !== undefined
    ) {
      const versionArray = firmwareLatest.version.split('.')

      if (value === 'major') {
        const majorUpdate: number = +versionArray[0]
        versionArray[0] = `${majorUpdate + 1}`
      } else if (value === 'minor') {
        const minorUpdate: number = +versionArray[1]
        versionArray[1] = `${minorUpdate + 1}`
      } else if (value === 'patch') {
        const patchUpdate: number = +versionArray[2]
        versionArray[2] = `${patchUpdate + 1}`
      }

      const submitFirmwareVersion = versionArray.join('.')

      updateFirmware(submitFirmwareVersion, readyToSubmitFile)
      this.handleClearState()
    }
  }

  render() {
    const { classes, show, handleClose, firmwareLatest, updateFirmware } =
      this.props
    const { file, fileSelected, value } = this.state

    return (
      <>
        <div>
          <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="add-firmware-dialog"
            maxWidth="lg"
          >
            <DialogTitle id="add-firmware-title">
              <Typography align="center">
                Latest Firmware Version: {firmwareLatest?.version}
              </Typography>
            </DialogTitle>

            <DialogContent>
              <form
                noValidate
                onSubmit={(e) =>
                  this.handleFormSubmit(
                    e,
                    file,
                    firmwareLatest,
                    value,
                    updateFirmware
                  )
                }
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  <RadioGroup
                    row
                    onChange={this.handleVersionChange}
                    value={value}
                  >
                    <FormControlLabel
                      value="major"
                      control={<Radio />}
                      label="Major"
                    />
                    <FormControlLabel
                      value="minor"
                      control={<Radio />}
                      label="Minor"
                    />
                    <FormControlLabel
                      value="patch"
                      control={<Radio />}
                      label="Patch"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    component="label"
                  >
                    Add File
                    <input
                      id="add-file"
                      type="file"
                      accept=".bin"
                      hidden
                      onChange={(e) => this.handleFileChange(e)}
                    />
                  </Button>
                </Grid>
                <Box pt={2}>
                  <Typography component="h1" align="center">
                    {fileSelected && <Typography> {file?.name}</Typography>}
                  </Typography>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={file === undefined}
                  onClick={handleClose}
                >
                  Upload
                </Button>
              </form>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleClose}>
                <Typography>Close</Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  }
}

export default withStyles(styles, { withTheme: true })(FirmwareUpdateFormDialog)
