import React from 'react'
import EditIcon from '@material-ui/icons/Edit'

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'

import RestoreInventoryItem from './inventory-item-restore.component'
import DepleteInventoryItem from './inventory-item-deplete.component'
import Reading from '../../models/Reading'
import Consumable from '../../models/Consumable'
import { convertUTCToLocalDatetime } from '../../utils/UtcConversionUtil'

const useEditStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: theme.spacing(0, 0),
  },
  icon: {
    color: '#fff',
  },
}))

interface Props {
  handleClose: () => void
  handleUnmountedVal: () => void
  readings: Reading[]
  consumable: Consumable | undefined
  show: boolean
  isDepleted?: boolean
}

interface EditConsumableIconButtonProps {
  consumableId: number
}

function EditConsumableIconButton(
  props: EditConsumableIconButtonProps
): JSX.Element {
  const classes = useEditStyles()
  const { consumableId } = props
  return (
    <>
      <Avatar variant="rounded" className={classes.avatar}>
        <IconButton href={`/portal/inventory-items/${consumableId}`}>
          <EditIcon className={classes.icon} fontSize="small" />
        </IconButton>
      </Avatar>
    </>
  )
}

export default class InventoryHistoryDialog extends React.Component<Props> {
  isMountedVal: boolean

  constructor(props: Props) {
    super(props)
    this.isMountedVal = false
  }

  componentDidMount = (): void => {
    this.isMountedVal = true
  }

  componentWillUnmount = (): void => {
    this.isMountedVal = false
    this.props.handleUnmountedVal()
  }

  render(): JSX.Element {
    const { consumable, show, readings, isDepleted, handleClose } = this.props
    return (
      <>
        <div>
          <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="add-consumable-dialog"
            maxWidth="lg"
          >
            <DialogTitle id="view-readings-dialog">Readings</DialogTitle>

            <DialogContent>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item xs={6}>
                  Edit Consumable
                  <EditConsumableIconButton
                    consumableId={consumable ? consumable.id : 0}
                  />
                </Grid>
                {!isDepleted && (
                  <Grid item xs={6}>
                    Deplete Consumable
                    <DepleteInventoryItem
                      consumableId={consumable ? consumable.id : 0}
                    />
                  </Grid>
                )}
                {isDepleted && (
                  <Grid item xs={6}>
                    Restore Consumable
                    <RestoreInventoryItem
                      consumableId={consumable ? consumable.id : 0}
                    />
                  </Grid>
                )}
              </Grid>
              {readings.length === 0 &&
                'There are no readings for consumable yet.'}
              {readings.length !== 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>TagId</TableCell>
                      <TableCell>Gross Weight</TableCell>
                      <TableCell>CreatedAt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {readings.map((reading: Reading) => (
                      <TableRow key={reading.readingId}>
                        <TableCell>{reading.tag.tagId}</TableCell>
                        <TableCell>{reading.weight}</TableCell>
                        <TableCell>
                          {convertUTCToLocalDatetime(
                            reading.createdAt.toString()
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  }
}
