import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import history from './utils/history'
import App from './App'
import * as serviceWorker from './serviceWorker'
import configureStore from './configureStore'

const initialState: any = {}
const store = configureStore(history, initialState)

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById('react')
)

serviceWorker.unregister()
