import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { USER_DETAILS_KEY } from '../../services/user-service'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem(USER_DETAILS_KEY) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/user/signin', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
