import React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { SeverityType } from 'store/snackbar/types'
import { openSnackBar as openSnackBarAction } from 'store/snackbar/action'
import Consumable from 'models/Consumable'
import { ApplicationState } from '../../store'
import { fetchConsumables } from '../../store/consumable/action'
import InventoryItemTable from './inventory-item-table.component'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

type MyRootState = ApplicationState
type MyExtraArg = Consumable
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, AnyAction>

class InventoryItems extends React.Component<Props> {
  componentDidMount = () => {
    const { fetchPage } = this.props
    fetchPage()
  }

  render() {
    const { errors, activeConsumablesPage, fetchPage, openSnackBar } =
      this.props
    if (!activeConsumablesPage.content) {
      return (
        <div>
          <LinearProgress />
        </div>
      )
    }

    if (errors) {
      // TODO: show an error screen
    }

    return (
      <div>
        <InventoryItemTable
          page={activeConsumablesPage}
          fetchActiveConsumablesPage={fetchPage}
          openSnackBar={openSnackBar}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ consumables }: ApplicationState) => ({
  activeConsumablesPage: consumables.activeConsumablesPage,
  errors: consumables.errors,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => {
  return {
    openSnackBar: (message: string, severity: SeverityType) => {
      openSnackBarAction(dispatch, message, severity)
    },
    fetchPage: (
      page?: number,
      size?: number,
      queryName?: string,
      queryParameter?: string
    ) =>
      dispatch(
        fetchConsumables(
          page,
          size,
          'lastModified,desc',
          queryName,
          queryParameter
        )
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryItems)
