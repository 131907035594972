import React, { useState } from 'react'
import {
  Toolbar,
  Table,
  TableContainer,
  Typography,
  Grid,
  TableFooter,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import FirmwareUpdateFormDialog from './firmware-update-form-dialog.component'
import Firmware from '../../models/Firmware'
import Page from '../../models/pagination/Page'
import TablePaginationActions from '../pagination/table-pagination-actions'
import FirmwareLatest from '../../models/FirmwareLatest'

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 10%',
  },
}))

interface Props {
  page: Page<Firmware>
  fetchLatestFirmware: FirmwareLatest
  fetchActiveFirmwareVersionsPage: (page?: number, size?: number) => void
  updateFirmware: (version: string, file: File) => void
}

export default function FirmwareTable(props: Props): JSX.Element {
  const classes = useStyles()
  const { page, fetchLatestFirmware, updateFirmware } = props
  const [show, setShow] = useState(false)
  const activeFirmwareVersions: Firmware[] = page.content ? page.content : []

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    props.fetchActiveFirmwareVersionsPage(newPage, props.page.size)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.fetchActiveFirmwareVersionsPage(0, Number(event.target.value))
  }

  const handleClose = () => setShow(false)

  const handleAddFirmwareClick = () => {
    setShow(true)
  }

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography
          component="h2"
          variant="inherit"
          color="textPrimary"
          gutterBottom
        />

        <TableContainer component={Paper}>
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              id="inventory-management-table"
              component="div"
            >
              Firmware Versions
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              component="label"
              onClick={handleAddFirmwareClick}
            >
              New Firmware
            </Button>
          </Toolbar>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Upload Date</TableCell>
                <TableCell>Uploaded By</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {activeFirmwareVersions.map((firmware: Firmware) => (
                <TableRow hover key={firmware.id}>
                  <TableCell>{firmware.name}</TableCell>
                  <TableCell>{firmware.version}</TableCell>
                  <TableCell>{firmware.createdAt}</TableCell>
                  <TableCell>{firmware.byUser}</TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  colSpan={10}
                  count={page.totalElements}
                  rowsPerPage={page.size}
                  page={page.number}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <FirmwareUpdateFormDialog
          handleClose={handleClose}
          show={show}
          firmwareLatest={fetchLatestFirmware}
          updateFirmware={updateFirmware}
        />
      </Grid>
    </Grid>
  )
}
