import { Reducer } from 'redux'
import { SnackBarActionTypes, SnackBarState, SeverityType } from './types'

export const initialState: SnackBarState = {
  open: false,
  message: '',
  severity: SeverityType.SUCCESS,
}

const reducer: Reducer<SnackBarState> = (state = initialState, action) => {
  switch (action.type) {
    case SnackBarActionTypes.OPEN: {
      return {
        ...state,
        open: true,
        message: action.message,
        severity: action.severity,
      }
    }
    case SnackBarActionTypes.CLOSE: {
      return { ...state, open: false }
    }
    default: {
      return state
    }
  }
}

export { reducer as SnackBarReducer }
