import { combineReducers, Reducer } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'

import { UserReducer } from './user/reducer'
import { UserState } from './user/types'

import { ConsumableReducer } from './consumable/reducer'
import { ConsumableState } from './consumable/types'

import { DeviceReducer } from './device/reducer'
import { DeviceState } from './device/types'

import { FirmwareReducer } from './firmware/reducer'
import { FirmwareState } from './firmware/types'

import { SnackBarReducer } from './snackbar/reducer'
import { SnackBarState } from './snackbar/types'

import { LabReducer } from './lab/reducer'
import { LabState } from './lab/types'

export interface ApplicationState {
  user: UserState
  consumables: ConsumableState
  devices: DeviceState
  firmware: FirmwareState
  lab: LabState
  snackbar: SnackBarState
  router: RouterState
}

export const createRootReducer = (
  history: History
): Reducer<ApplicationState> =>
  combineReducers({
    user: UserReducer,
    consumables: ConsumableReducer,
    devices: DeviceReducer,
    firmware: FirmwareReducer,
    lab: LabReducer,
    snackbar: SnackBarReducer,
    router: connectRouter(history),
  })
