import moment from 'moment'

// Convert UTC datetime from backend to local datetime for display
const convertUTCToLocalDatetime = (utcDate: string): string => {
  return moment.utc(utcDate).local().format('YYYY-MM-DD hh:mm:ss A')
}

// Convert local datetime to UTC datetime before sending to backend
const convertLocalToUTCDatetime = (localDate: string): string => {
  return moment(localDate).utc().format('YYYY-MM-DD HH:mm:ss')
}

// Convert UTC date from backend to local time for display
const convertUTCToLocalDate = (utcDate: string): string => {
  return moment.utc(utcDate).local().format('YYYY-MM-DD')
}

// Convert local date to UTC date before sending to backend
const convertLocalToUTCDate = (localDate: string): string => {
  return moment(localDate).utc().format('YYYY-MM-DD')
}

export {
  convertUTCToLocalDatetime,
  convertLocalToUTCDatetime,
  convertUTCToLocalDate,
  convertLocalToUTCDate,
}
