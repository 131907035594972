import React from 'react'
import { connect } from 'react-redux'
import ManageLabSpace from './manage-labspace.component'
import { ApplicationState } from '../../store/index'
import Lab from '../../models/Lab'

type Props = {
  lab: Lab // Ensure lab is required
}

const ManageLabSpaceContainer = (
  props: Props
): React.FunctionComponentElement<Props> => {
  const { lab } = props
  return <ManageLabSpace lab={lab} />
}

const mapStateToProps = ({ lab }: ApplicationState) => ({
  lab: lab.lab,
})

export default connect(mapStateToProps, null)(ManageLabSpaceContainer)
