export enum SnackBarActionTypes {
  OPEN = '@@snackbar/OPEN',
  CLOSE = '@@snackbar/CLOSE',
}

export enum SeverityType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface SnackBarState {
  readonly open: boolean
  readonly message: string
  readonly severity: 'success' | 'warning' | 'error' | 'info'
}
