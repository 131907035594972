import React, { FormEvent, useState } from 'react'
import moment from 'moment'

import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { format } from 'date-fns'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import AddConsumable from 'models/AddConsumable'
import axios, { AxiosError, AxiosResponse } from 'axios'
import SSTErrorResponse from 'exceptions/SSTErrorResponse'
import { SeverityType } from '../../store/snackbar/types'
import { httpPostRequest } from '../../utils/HttpUtil'

const API_URL = `${process.env.REACT_APP_API_URL}`

const styles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  openSnackBar: (message: string, severity: SeverityType) => void
}

const isUnitVolumetric = (unit: string | undefined) => {
  if (unit) return ['l', 'ml', 'floz'].includes(unit)
  return false
}

function NewInventoryItemModal(props: Props): JSX.Element {
  const { classes, openSnackBar } = props
  const [show, setShow] = useState<boolean>(false)
  const [expiration, setExpiration] = useState<string | undefined>()
  const [receiveDate, setReceiveDate] = useState<string | undefined>(
    moment().format('YYYY-MM-DD')
  )
  const [unit, setUnit] = useState<string | undefined>('g')
  const [density, setDensity] = useState<string | undefined>('1.0')

  const handleExpirationDateChange = (value?: string | null) => {
    setExpiration(value || '')
  }

  const handleReceiveDateChange = (value?: string | null) => {
    setReceiveDate(value || '')
  }

  const resetState = () => {
    setReceiveDate(moment().format('YYYY-MM-DD'))
    setDensity('1.0')
    setUnit('g')
    window.location.reload()
  }

  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
    resetState()
  }

  const handleDialogSubmit = async (
    event: FormEvent<HTMLFormElement | HTMLDivElement>
  ) => {
    event.preventDefault()
    const form = event.currentTarget as HTMLFormElement
    const formData = new FormData(form)
    const formJson: { [key: string]: string | File } = {}

    // Convert FormData to an object
    formData.forEach((value, key) => {
      formJson[key] = value
    })

    const contentWeight = Number.parseFloat(formJson.contentWeight as string)

    const requestBody: AddConsumable = {
      ...formJson,
      contentWeight,
      receiveDate,
      expiration,
    }

    const { itemName } = requestBody

    if (itemName == null || contentWeight == null) {
      openSnackBar(
        'Item Name, Product Number and Initial Content Weight required!',
        SeverityType.ERROR
      )
      return
    }

    if (!/^-?\d+(\.\d+)?$/.test(formJson.contentWeight as string)) {
      openSnackBar(
        'Initial Content Weight must be a number.',
        SeverityType.ERROR
      )
      return
    }

    if (contentWeight <= 0) {
      openSnackBar(
        'Initial Content Weight must be greater than zero.',
        SeverityType.ERROR
      )
      return
    }

    try {
      await httpPostRequest<AddConsumable>(
        `${API_URL}/api/v1/consumables`,
        requestBody
      )
      openSnackBar('Successfully created new consumable.', SeverityType.SUCCESS)
    } catch (e) {
      let errorMessage = 'Error attempting to create consumable: '

      if (axios.isAxiosError(e)) {
        const axiosErr: AxiosError = e
        console.error(axiosErr.message)
        errorMessage += axiosErr.message
      } else {
        const error: AxiosResponse<SSTErrorResponse> =
          e as AxiosResponse<SSTErrorResponse>

        if (error.data.errors && error.data.errors.length)
          errorMessage += error.data.errors.map((err) => err.errorMessage)
        else errorMessage += error.data.errors
      }

      openSnackBar(errorMessage, SeverityType.ERROR)
    } finally {
      resetState()
      handleClose()
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleShow}
      >
        Add Consumable
      </Button>

      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="add-consumable-dialog"
        PaperProps={{
          component: 'form',
          onSubmit: handleDialogSubmit,
        }}
      >
        <DialogTitle id="add-consumable-dialog-title">
          Add Consumable
        </DialogTitle>

        <DialogContent>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="itemName"
                name="itemName"
                label="Item Name"
                type="text"
                fullWidth
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="supplier"
                label="Supplier"
                fullWidth
                variant="standard"
                type="text"
                placeholder="Supplier"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="casNumber"
                label="CAS Number"
                fullWidth
                variant="standard"
                type="text"
                placeholder="CAS Number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="productNumber"
                label="Product Number"
                fullWidth
                variant="standard"
                type="text"
                placeholder="Product Number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="lotNumber"
                label="Lot Number"
                fullWidth
                variant="standard"
                type="text"
                placeholder="Lot Number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="location"
                label="Storage Location"
                fullWidth
                variant="standard"
                type="text"
                placeholder="Storage Location"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                name="contentWeight"
                label={`Initial Content ${
                  isUnitVolumetric(unit) ? 'Volume' : 'Weight'
                }`}
                fullWidth
                variant="standard"
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="unit">Unit</InputLabel>
              <Select
                required
                margin="dense"
                name="unit"
                label="Unit"
                fullWidth
                variant="standard"
                value={unit}
                onChange={(event) => setUnit(event.target.value as string)}
                disabled
              >
                <MenuItem value="g">g</MenuItem>
                {/* <MenuItem value="ml">ml</MenuItem> */}
              </Select>
            </Grid>
            {isUnitVolumetric(unit) && (
              <Grid item xs={6}>
                <TextField
                  required
                  margin="dense"
                  name="density"
                  label="Density (g/mL)"
                  fullWidth
                  variant="standard"
                  type="text"
                  value={density}
                  onChange={(event) => setDensity(event.target.value as string)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="dense"
                  id="receiveDate"
                  label="ReceiveDate"
                  value={null}
                  inputValue={receiveDate}
                  onChange={(_, newValue) => handleReceiveDateChange(newValue)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="dense"
                  id="expiration"
                  label="Expiration"
                  value={null}
                  inputValue={expiration}
                  minDate={new Date()}
                  minDateMessage={`Expiration date cannot be set anytime before '${format(
                    new Date(),
                    'yyyy-MM-dd'
                  )}'.`}
                  onChange={(_, newValue) =>
                    handleExpirationDateChange(newValue)
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(NewInventoryItemModal)
