import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import { httpGetRequest } from '../../utils/HttpUtil'
import User from '../../models/User'
import Lab from '../../models/Lab'

import LabMenu from '../custom/lab-menu.component'
import SendLabInvitationDialog from './send-lab-invitation-dialog.component'

const API_URL = `${process.env.REACT_APP_API_URL}`

const styles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    grid: {
      padding: theme.spacing(1),
    },
    root: {
      width: '100%',
      alignItems: 'left',
    },
    table: {
      minWidth: 'auto',
    },
    title: {
      flex: '1 1 10%',
    },
    labMenu: {
      height: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })

interface Props extends WithStyles<typeof styles> {
  lab?: Lab
}

interface State {
  users: User[]
}

class ManageLabSpace extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      users: [],
    }
  }

  componentDidMount() {
    this.fetchAllUsers()
  }

  fetchAllUsers = async () => {
    try {
      const response = await httpGetRequest<User[]>(
        `${API_URL}/api/v1/lab/users`
      )
      const data = await response.data
      this.setState({ users: data })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { classes, lab } = this.props
    const { users } = this.state
    return (
      <Container>
        <Grid
          className={classes.grid}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Typography
              component="h2"
              variant="inherit"
              color="textPrimary"
              gutterBottom
            >
              Manage Lab Space
            </Typography>
          </Grid>
          <Grid item xs={4} container justify="flex-end">
            <LabMenu lab={lab} labs={undefined} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid xs={8}>
            <TableContainer component={Paper}>
              <Toolbar>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Members
                </Typography>

                <Tooltip title="Invite User">
                  <SendLabInvitationDialog />
                </Tooltip>
              </Toolbar>
              <Table
                className={classes.table}
                aria-label="user management table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user: User) => (
                    <TableRow key={user.userId}>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>
                        <FormControl className={classes.formControl}>
                          <Select
                            value={user.userRoles[0].roleType.roleName}
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem value="ROLE_ADMIN">ROLE_ADMIN</MenuItem>
                            <MenuItem value="ROLE_USER">ROLE_USER</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ManageLabSpace)
