import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Image } from 'react-bootstrap'
import { AxiosResponse } from 'axios'
import { Link as RouterLink } from 'react-router-dom'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { httpPostRequest } from '../../utils/HttpUtil'
import AddUser from '../../models/AddUser'
import { SeverityType } from '../../store/snackbar/types'
import FieldErrorMessage from '../../models/FieldErrorMessage'

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.scaledsolutionstech.com/">
        ScaledSolutionsTech
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })

const urlParams = new URLSearchParams(window.location.search)
const API_URL = `${process.env.REACT_APP_API_URL}`

interface Props extends WithStyles<typeof styles> {
  callBack: (message: string, severity: SeverityType) => void
}

function SignUp(props: Props): JSX.Element {
  const { classes } = props

  const emailParam = urlParams.get('email')
  const [email] = useState<string>(emailParam || '')
  const [labToken] = useState<string | null>(urlParams.get('lab_token'))

  const { control, handleSubmit, watch } = useForm<AddUser>()
  const password = watch('password', '')

  const onSubmit: SubmitHandler<AddUser> = async (data) => {
    const addUser: AddUser = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      roles: [],
      labInvitationToken: labToken,
    }
    try {
      await httpPostRequest<AddUser>(
        `${API_URL}/api/v1/account/register`,
        addUser
      )
      props.callBack('New user successfully signed up!', SeverityType.SUCCESS)
      // handle navigation if needed
    } catch (e) {
      const error = e as AxiosResponse
      props.callBack(JSON.stringify(error.data), SeverityType.ERROR)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography align="center" gutterBottom>
          <Image
            src={`${process.env.PUBLIC_URL}/flat-transparent-logo.png`}
            width="40"
            height="50"
            fluid
          />
        </Typography>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                rules={{ required: FieldErrorMessage.FIELD_REQUIRED_MESSAGE }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    value={value}
                    onChange={onChange}
                    {...(error && { error: true, helperText: error.message })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                rules={{ required: FieldErrorMessage.FIELD_REQUIRED_MESSAGE }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    value={value}
                    onChange={onChange}
                    {...(error && { error: true, helperText: error.message })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue={email}
                rules={{
                  required: FieldErrorMessage.FIELD_REQUIRED_MESSAGE,
                  pattern: {
                    value: /^\S+@\S+$/,
                    message: FieldErrorMessage.FIELD_NOT_EMAIL_MESSAGE,
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    disabled={urlParams.has('email')}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={value}
                    onChange={onChange}
                    {...(error && { error: true, helperText: error.message })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                defaultValue=""
                control={control}
                rules={{ required: FieldErrorMessage.FIELD_REQUIRED_MESSAGE }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="new-password"
                    autoComplete="new-password"
                    value={value}
                    onChange={onChange}
                    {...(error && { error: true, helperText: error.message })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: FieldErrorMessage.FIELD_REQUIRED_MESSAGE,
                  validate: (value) => value === password,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirm-password"
                    autoComplete="new-password"
                    value={value}
                    onChange={onChange}
                    {...(error && { error: true, helperText: error.message })}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link component={RouterLink} to="/user/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default withStyles(styles, { withTheme: true })(SignUp)
