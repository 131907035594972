import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { AxiosResponse } from 'axios'
import { openSnackBar } from '../../store/snackbar/action'
import UpdatePasswordDetails from '../../models/UpdatePasswordDetails'
import { httpGetRequest, httpPutRequest } from '../../utils/HttpUtil'
import { SeverityType } from '../../store/snackbar/types'
import ApiAxiosResponseError from '../../exceptions/ApiAxiosResponseError'
import FieldErrorMessage from '../../models/FieldErrorMessage'

const API_URL = `${process.env.REACT_APP_API_URL}`
const urlParams = new URLSearchParams(window.location.search)

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '85vh',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
}))

export default function UpdatePassword(): JSX.Element {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const [token] = useState<string | null>(urlParams.get('token'))

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfimPassword] = useState<boolean>(false)

  const { control, handleSubmit, watch } = useForm<UpdatePasswordDetails>()
  const password = useRef<string | null>()
  password.current = watch('newPassword', '')

  const validateToken = async () => {
    try {
      const response = await httpGetRequest<boolean>(
        `${API_URL}/api/v1/account/password-reset-token/${token}/is-valid`
      )
      const tokenIsValid = await response.data
      if (!tokenIsValid) {
        openSnackBar(
          dispatch,
          `Token ${token} was invalid, password cannot be reset.`,
          SeverityType.ERROR
        )
        history.push('/user/signin')
      }
    } catch (e) {
      const error = e as AxiosResponse
      console.error(JSON.stringify(error.data))
      openSnackBar(dispatch, JSON.stringify(error.data), SeverityType.ERROR)
      history.push('/user/signin')
    }
  }

  useEffect(() => {
    validateToken()
  })

  const onSubmit: SubmitHandler<UpdatePasswordDetails> = async (data) => {
    const details: UpdatePasswordDetails = {
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
      token,
    }

    console.warn(details)
    try {
      await httpPutRequest<UpdatePasswordDetails>(
        `${API_URL}/api/v1/account/update-password`,
        details
      )
      openSnackBar(
        dispatch,
        'Password reset was successful!',
        SeverityType.SUCCESS
      )

      history.push('/user/signin')
    } catch (e) {
      const error = e as ApiAxiosResponseError
      console.error(error.message)
      openSnackBar(dispatch, error.message, SeverityType.ERROR)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfirmedPassword = () => {
    setShowConfimPassword(!showConfirmPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h6" align="center">
          ScaledSolutions Password Reset
        </Typography>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            rules={{ required: FieldErrorMessage.FIELD_REQUIRED_MESSAGE }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="newPassword"
                label="New Password"
                id="new-password"
                type={showPassword ? 'text' : 'password'}
                onChange={onChange}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...(error && { error: true, helperText: error.message })}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: FieldErrorMessage.FIELD_REQUIRED_MESSAGE,
              validate: {
                passwordMatch: (value) =>
                  value === password.current ||
                  FieldErrorMessage.PASSWORD_NOT_MATCH,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                id="confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={onChange}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmedPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...(error && { error: true, helperText: error.message })}
              />
            )}
          />
          <FormControl className={clsx(classes.margin)} fullWidth>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Reset
            </Button>
          </FormControl>
        </form>
      </div>
    </Container>
  )
}
