import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

/* Material UI */
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ListIcon from '@material-ui/icons/List'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { Image } from 'react-bootstrap'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Security } from '@material-ui/icons'

const DRAWER_WIDTH = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
  },
}))

interface Props {
  openDrawer: boolean
  isAdmin: boolean
  setOpenDrawer: (open: boolean) => void
}

export default function DrawerContainer(props: Props): JSX.Element {
  const { openDrawer, isAdmin, setOpenDrawer } = props
  const classes = useStyles()
  const theme = useTheme()
  const [openAdmin, setOpenAdmin] = React.useState(false)
  const [openInventory, setOpenInventory] = React.useState(false)

  const handleDrawerClose = () => {
    setOpenDrawer(false)
    setOpenAdmin(false)
    setOpenInventory(false)
  }

  const handleAdminMenuOpen = () => {
    if (!openDrawer) {
      setOpenDrawer(true)
    }
    setOpenAdmin(!openAdmin)
  }

  const handleInventoryMenuOpen = () => {
    if (!openDrawer) {
      setOpenDrawer(true)
    }
    setOpenInventory(!openInventory)
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: openDrawer,
        [classes.drawerClose]: !openDrawer,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <Image
          src={`${process.env.PUBLIC_URL}/flat-transparent-logo.png`}
          className="mr-4"
          width="30"
          height="40"
          fluid
        />
        <Typography variant="h6" align="center">
          SST Portal
        </Typography>
        <IconButton size="small" onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem key="Dashboard" component={Link} to="/portal" button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem button onClick={handleInventoryMenuOpen}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Consumables" />
          {openInventory ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openInventory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              key="Inventory Items"
              component={Link}
              to="/portal/inventory-items"
            >
              <ListItemIcon />
              <ListItemText primary="Inventory Items" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              key="Depleted Items"
              component={Link}
              to="/portal/inventory-items/depleted-consumables"
            >
              <ListItemIcon />
              <ListItemText primary="Depleted Items" />
            </ListItem>
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItem key="Billing" component={Link} to="/portal/billing" button>
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItem>
        {isAdmin && (
          <ListItem button onClick={handleAdminMenuOpen}>
            <ListItemIcon>
              <Security />
            </ListItemIcon>
            <ListItemText primary="Admin" />
            {openAdmin ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
        <Collapse in={openAdmin} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              key="Firmware"
              component={Link}
              to="/portal/admin/firmware"
            >
              <ListItemIcon />
              <ListItemText primary="Firmware" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              key="Manage Lab Space"
              component={Link}
              to="/portal/account/settings/labspace"
            >
              <ListItemIcon />
              <ListItemText primary="Manage Lab Space" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  )
}
