import React from 'react'
import { Store } from 'redux'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'
import './App.scss'

import { ThemeProvider } from '@material-ui/core/styles'
import Routes from './routes'
import { ApplicationState } from './store'
import ScaledSolutionsPortalTheme from './theme'

interface MainProps {
  store: Store<ApplicationState>
  history: History
}

const App = (props: MainProps): JSX.Element => {
  const { store, history } = props
  return (
    <ThemeProvider theme={ScaledSolutionsPortalTheme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  )
}

export default App
