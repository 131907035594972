import React, { useState } from 'react'
import {
  Toolbar,
  Table,
  TableContainer,
  Typography,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InventoryHistoryDialog from '../inventory-history-dialog.component'
import Consumable from '../../../models/Consumable'
import Page from '../../../models/pagination/Page'
import Reading from '../../../models/Reading'
import { httpGetRequest } from '../../../utils/HttpUtil'

interface Props {
  page: Page<Consumable>
  fetchDepletedConsumables: (page?: number, size?: number) => void
}

const API_URL: string = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : ''

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 1000,
  },
  title: {
    flex: '1 1 10%',
  },
}))

export default function InventoryDepletedTable(props: Props): JSX.Element {
  const classes = useStyles()
  const { page } = props
  const [, setIsMountedVal] = useState(false)
  const [show, setShow] = useState(false)
  const [readings, setReadings] = useState<Reading[]>([])
  const [consumable, setConsumable] = useState<Consumable>()

  const handleUnmountedVal = () => setIsMountedVal(false)

  const handleClose = () => setShow(false)

  const depletedConsumables: Consumable[] = page.content ? page.content : []

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    props.fetchDepletedConsumables(newPage, props.page.size)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.fetchDepletedConsumables(0, Number(event.target.value))
  }

  const fetchReadingsForConsumable = async (tagId: string) => {
    try {
      const response = await httpGetRequest<Reading[]>(
        `${API_URL}/api/v1/readings/${tagId}`
      )
      setReadings(response.data)
    } catch (e) {
      console.error(e)
    }
  }

  const handleClick = (clicked_consumable: Consumable) => {
    if (clicked_consumable.tag) {
      fetchReadingsForConsumable(clicked_consumable.tag?.tagId)
      setConsumable(clicked_consumable)
      setShow(true)
    }
  }

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography
          component="h2"
          variant="inherit"
          color="textPrimary"
          gutterBottom
        >
          Depleted Items
        </Typography>

        <TableContainer component={Paper}>
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              id="inventory-management-table"
              component="div"
            >
              Depleted Inventory Management Table
            </Typography>
          </Toolbar>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item Name</TableCell>
                <TableCell>Product Number</TableCell>
                <TableCell>CAS Number</TableCell>
                <TableCell>Lot Number</TableCell>
                <TableCell>Storage Location</TableCell>
                <TableCell>Net Weight</TableCell>
                <TableCell>Units</TableCell>
                <TableCell>Expiration</TableCell>
                <TableCell>Last Modified</TableCell>
                <TableCell>TagId</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {depletedConsumables.map((i_consumable: Consumable) => (
                <TableRow
                  hover
                  key={i_consumable.id}
                  onClick={() => handleClick(i_consumable)}
                >
                  <TableCell>{i_consumable.itemName}</TableCell>
                  <TableCell>{i_consumable.productNumber}</TableCell>
                  <TableCell>{i_consumable.casNumber}</TableCell>
                  <TableCell>{i_consumable.lotNumber}</TableCell>
                  <TableCell>{i_consumable.location}</TableCell>
                  <TableCell>{i_consumable.netWeight}</TableCell>
                  <TableCell>{i_consumable.unit}</TableCell>
                  <TableCell>{i_consumable.expiration}</TableCell>
                  <TableCell>{i_consumable.lastModified}</TableCell>
                  <TableCell>{i_consumable.tag?.tagId}</TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  colSpan={10}
                  count={page.totalElements}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page.number}
                  rowsPerPage={page.size}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <InventoryHistoryDialog
          isDepleted
          show={show}
          handleClose={handleClose}
          handleUnmountedVal={handleUnmountedVal}
          readings={readings}
          consumable={consumable}
        />
      </Grid>
    </Grid>
  )
}
