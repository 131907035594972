import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Toolbar,
  Table,
  TableContainer,
  Typography,
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { httpGetRequest } from '../../../utils/HttpUtil'
import { openSnackBar } from '../../../store/snackbar/action'
import { SeverityType } from '../../../store/snackbar/types'

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 10%',
  },
}))

interface ApiVerification {
  apiKey: string
  barcodeKey: string
  defaultVaultId: string
  mappingTemplate: string
  isVerified: boolean
  isConfirmed: boolean
}

interface CddSelectOptions {
  id: string
  name: string
}

const API_URL = `${process.env.REACT_APP_API_URL}`

export default function CddApiIntegration(): JSX.Element {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [apiVerification, setApiVerification] = useState<ApiVerification>({
    apiKey: '',
    barcodeKey: '',
    defaultVaultId: '',
    mappingTemplate: '',
    isVerified: false,
    isConfirmed: false,
  })

  const [mappingTemplates, setMappingTemplates] = useState<CddSelectOptions[]>()
  const [vaults, setVaults] = useState<CddSelectOptions[]>()

  useEffect(() => {
    httpGetRequest<{
      apiKey: string
      batchSearchField: string
      defaultVaultId: string
      mappingTemplate: string
    }>(`${API_URL}/api/v1/cdd/credential`)
      .then((res) => {
        console.warn(res.data)
        if (!res.data) {
          openSnackBar(dispatch, 'Credential not found.', SeverityType.ERROR)
        } else {
          setApiVerification({
            apiKey: res.data.apiKey,
            barcodeKey: res.data.batchSearchField,
            defaultVaultId: res.data.defaultVaultId,
            mappingTemplate: res.data.mappingTemplate,
            isVerified: false,
            isConfirmed: true,
          })
          openSnackBar(dispatch, 'Credential found!', SeverityType.SUCCESS)
        }
      })
      .catch(() => {
        openSnackBar(dispatch, 'Credential not found.', SeverityType.ERROR)
      })
  }, [dispatch])

  const onApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiVerification({
      ...apiVerification,
      apiKey: event.currentTarget.value,
    })
  }

  const onBarcodeKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiVerification({
      ...apiVerification,
      barcodeKey: event.currentTarget.value,
    })
  }

  const onVaultChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    try {
      httpGetRequest(
        `${API_URL}/api/v1/cdd/mapping-templates?key=${apiVerification.apiKey}&vaultId=${event.target.value}`
      ).then((res) => {
        const options: CddSelectOptions[] = res.data as CddSelectOptions[]
        setMappingTemplates(options)
      })

      openSnackBar(
        dispatch,
        'Token successfully verified!',
        SeverityType.SUCCESS
      )
    } catch (e) {
      openSnackBar(dispatch, 'Could not verify CDD Token.', SeverityType.ERROR)
    }
    setApiVerification({
      ...apiVerification,
      defaultVaultId: event.target.value as string,
    })
  }

  const onMappingTemplateChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    setApiVerification({
      ...apiVerification,
      mappingTemplate: event.target.value as string,
    })
  }

  const onVerify = async () => {
    try {
      httpGetRequest(
        `${API_URL}/api/v1/cdd/verify-api-key?key=${apiVerification.apiKey}`
      ).then((res) => {
        setApiVerification({ ...apiVerification, isVerified: true })
        const options: CddSelectOptions[] = res.data as CddSelectOptions[]
        setVaults(options)
      })

      openSnackBar(
        dispatch,
        'Token successfully verified!',
        SeverityType.SUCCESS
      )
    } catch (e) {
      openSnackBar(dispatch, 'Could not verify CDD Token.', SeverityType.ERROR)
    }
  }

  const onConfirm = async () => {
    const CONFIRM_URL = `${API_URL}/api/v1/cdd/confirm-api-key?key=${apiVerification.apiKey}&batchSearchField=${apiVerification.barcodeKey}&vaultId=${apiVerification.defaultVaultId}&mappingTemplate=${apiVerification.mappingTemplate}`
    try {
      await httpGetRequest(CONFIRM_URL)

      setApiVerification({
        ...apiVerification,
        isVerified: false,
        isConfirmed: true,
      })
      openSnackBar(dispatch, 'Token saved!', SeverityType.SUCCESS)
    } catch (e) {
      openSnackBar(dispatch, 'Could not save Token.', SeverityType.ERROR)
    }
  }

  const onReset = async () => {
    setApiVerification({
      apiKey: '',
      barcodeKey: '',
      defaultVaultId: '',
      mappingTemplate: '',
      isVerified: false,
      isConfirmed: false,
    })
    setVaults(undefined)
    setMappingTemplates(undefined)
  }

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start">
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              id="integration-table"
              component="div"
            >
              CDD Integration
            </Typography>
          </Toolbar>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  CDD API Key&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.collaborativedrug.com/hc/en-us/articles/115005682263-Token-based-Authentication"
                  >
                    More Info
                  </a>
                </TableCell>
                <TableCell colSpan={9}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="api-key"
                    label="CDD API Key"
                    id="api-key"
                    value={apiVerification.apiKey}
                    onChange={onApiKeyChange}
                    disabled={apiVerification.isVerified}
                  />
                </TableCell>
                <TableCell colSpan={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onVerify}
                    disabled={apiVerification.isVerified}
                  >
                    Verify
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  CDD Barcode Identifier&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.collaborativedrug.com/hc/en-us/articles/213336386-Barcoding-the-inventory"
                  >
                    More Info
                  </a>
                </TableCell>
                <TableCell colSpan={9}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="barcode-key"
                    label="i.e. Barcode"
                    id="api-key"
                    value={apiVerification.barcodeKey}
                    onChange={onBarcodeKeyChange}
                    disabled={
                      apiVerification.isVerified && apiVerification.isConfirmed
                    }
                  />
                </TableCell>
                <TableCell colSpan={1} />
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  CDD Vault&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.collaborativedrug.com/hc/en-us/articles/4410168256276-General-Vault-Settings"
                  >
                    More Info
                  </a>
                </TableCell>
                <TableCell colSpan={9}>
                  <Select
                    labelId="vault-id"
                    id="vault-id"
                    fullWidth
                    // value={
                    //   apiVerification.defaultVaultId
                    //     ? apiVerification.defaultVaultId
                    //     : ''
                    // }
                    label="Vault"
                    onChange={onVaultChange}
                    // onSelect={onVaultSelect}
                    disabled={!vaults}
                  >
                    {vaults &&
                      vaults.map((t) => {
                        return (
                          <MenuItem key={t.id} value={t.id}>
                            {t.name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </TableCell>
                <TableCell colSpan={1} />
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  CDD Mapping Template&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.collaborativedrug.com/hc/en-us/articles/360044568531-Using-Templates-to-Map-Fields-for-Import"
                  >
                    More Info
                  </a>
                </TableCell>
                <TableCell colSpan={9}>
                  <Select
                    labelId="mapping-template"
                    id="mapping-template"
                    fullWidth
                    value={
                      apiVerification.mappingTemplate
                        ? apiVerification.mappingTemplate
                        : ''
                    }
                    label="Mapping Template"
                    onChange={onMappingTemplateChange}
                    disabled={!mappingTemplates}
                  >
                    {mappingTemplates &&
                      mappingTemplates.map((t) => {
                        return (
                          <MenuItem key={t.id} value={t.id}>
                            {t.name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </TableCell>
                <TableCell colSpan={1} />
              </TableRow>
              <TableRow>
                <TableCell colSpan={1}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                    disabled={
                      !apiVerification.isVerified ||
                      apiVerification.barcodeKey.length === 0
                    }
                  >
                    Confirm
                  </Button>
                </TableCell>
                <TableCell colSpan={1}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={onReset}
                    disabled={
                      !apiVerification.isVerified || apiVerification.isConfirmed
                    }
                  >
                    Reset
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
