import { ActionCreator, Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ConsumableActionTypes } from './types'
import Consumable from '../../models/Consumable'
import Page from '../../models/pagination/Page'
import ApiResponseError from '../../exceptions/ApiResponseError'
import { ApplicationState } from '../index'
import {
  httpDeleteRequest,
  httpGetRequest,
  httpPostRequestWithoutBody,
} from '../../utils/HttpUtil'
import {
  convertUTCToLocalDate,
  convertUTCToLocalDatetime,
} from '../../utils/UtcConversionUtil'

const API_URL = `${process.env.REACT_APP_API_URL}`

export type AppThunk = ThunkAction<
  void,
  ApplicationState,
  Consumable,
  Action<string>
>

const handleApiError = (error: unknown, actionType: ConsumableActionTypes) => {
  const apiError = error as ApiResponseError
  return {
    type: actionType,
    errors: JSON.stringify(apiError),
  }
}

export const fetchConsumables: ActionCreator<AppThunk> = (
  page = 0,
  size = 5,
  sort = '',
  queryName?: string,
  queryParameter?: string
) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: ConsumableActionTypes.FETCH_ACTIVE_CONSUMABLE_REQUEST })
    let consumableUrl = `${API_URL}/api/v1/consumables?page=${page}&size=${size}&sort=${sort}`

    if (queryName && queryParameter) {
      consumableUrl += `&${queryName}=${encodeURIComponent(queryParameter)}`
    }

    try {
      const response = await httpGetRequest<Page<Consumable>>(consumableUrl)
      const consumables = response.data.content.map((consumable) => ({
        ...consumable,
        receiveDate: convertUTCToLocalDate(consumable.receiveDate),
        expiration: convertUTCToLocalDate(consumable.expiration),
        createdAt: convertUTCToLocalDatetime(consumable.createdAt),
        lastModified: convertUTCToLocalDatetime(consumable.lastModified),
      }))

      return dispatch({
        type: ConsumableActionTypes.FETCH_ACTIVE_CONSUMABLE_SUCCESS,
        payload: { ...response.data, content: consumables },
      })
    } catch (error) {
      return dispatch(
        handleApiError(
          error,
          ConsumableActionTypes.FETCH_ACTIVE_CONSUMABLE_ERROR
        )
      )
    }
  }
}

export const fetchDepletedConsumables: ActionCreator<AppThunk> = (
  page = 0,
  size = 5
) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: ConsumableActionTypes.FETCH_DEPLETED_CONSUMABLE_REQUEST })

    try {
      const response = await httpGetRequest<Page<Consumable>>(
        `${API_URL}/api/v1/consumables?type=depleted&page=${page}&size=${size}`
      )
      const consumables = response.data.content.map((consumable) => ({
        ...consumable,
        receiveDate: convertUTCToLocalDate(consumable.receiveDate),
        expiration: convertUTCToLocalDate(consumable.expiration),
        createdAt: convertUTCToLocalDatetime(consumable.createdAt),
        lastModified: convertUTCToLocalDatetime(consumable.lastModified),
      }))

      return dispatch({
        type: ConsumableActionTypes.FETCH_DEPLETED_CONSUMABLE_SUCCESS,
        payload: { ...response.data, content: consumables },
      })
    } catch (error) {
      return dispatch(
        handleApiError(
          error,
          ConsumableActionTypes.FETCH_DEPLETED_CONSUMABLE_ERROR
        )
      )
    }
  }
}

export const depleteConsumableById: ActionCreator<AppThunk> = (id: number) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: ConsumableActionTypes.DEPLETE_CONSUMABLE_REQUEST })

    try {
      await httpDeleteRequest(`${API_URL}/api/v1/consumables/${id}/depleted`)
      return dispatch({
        type: ConsumableActionTypes.DEPLETE_CONSUMABLE_REQUEST_SUCCESS,
        id,
      })
    } catch (error) {
      return dispatch(
        handleApiError(
          error,
          ConsumableActionTypes.FETCH_DEPLETED_CONSUMABLE_ERROR
        )
      )
    }
  }
}

export const restoreConsumableById: ActionCreator<AppThunk> = (id: number) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: ConsumableActionTypes.RESTORE_CONSUMABLE_REQUEST })

    try {
      await httpPostRequestWithoutBody(
        `${API_URL}/api/v1/consumables/${id}/restore`
      )
      return dispatch({
        type: ConsumableActionTypes.RESTORE_CONSUMABLE_REQUEST_SUCCESS,
        id,
      })
    } catch (error) {
      return dispatch(
        handleApiError(
          error,
          ConsumableActionTypes.RESTORE_CONSUMABLE_REQUEST_ERROR
        )
      )
    }
  }
}
