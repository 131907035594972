import { Reducer } from 'redux'
import { DeviceActionTypes, DeviceState } from './types'
import Page from '../../models/pagination/Page'
import Device from '../../models/Device'
import UpdateDevice from '../../models/UpdateDevice'

export const initialState: DeviceState = {
  data: {} as Page<Device>,
  errors: undefined,
  loading: false,
}

const reducer: Reducer<DeviceState> = (state = initialState, action) => {
  switch (action.type) {
    case DeviceActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case DeviceActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: undefined,
      }
    }
    case DeviceActionTypes.UPDATE_REQUEST: {
      return { ...state, loading: true }
    }
    case DeviceActionTypes.UPDATE_SUCCESS: {
      const updateDevice = action.updateDevice as UpdateDevice
      const index = state.data.content.findIndex(
        (device) => device.id === action.deviceId
      )
      const device: Device = state.data.content[index]
      device.commonName = updateDevice.commonName
        ? updateDevice.commonName
        : device.commonName
      device.serialNumber = updateDevice.serialNumber
        ? updateDevice.serialNumber
        : device.serialNumber
      device.location = updateDevice.location
        ? updateDevice.location
        : device.location
      return { ...state, loading: false, errors: undefined }
    }
    case DeviceActionTypes.ERROR: {
      console.error(action.errors)
      return { ...state, loading: false, errors: action.errors }
    }
    default: {
      return state
    }
  }
}

export { reducer as DeviceReducer }
