import HttpStatus from 'http-status-codes'
import LoginUser from '../models/LoginUser'
import JwtResponse from '../models/JwtResponse'
import ApiResponseError from '../exceptions/ApiResponseError'

const USER_DETAILS_KEY = 'USER_DETAILS'
const OAUTH_TOKEN_URL = `${process.env.REACT_APP_OAUTH_URL}/oauth/token`
const OAUTH_AUTH_KEY = `${process.env.REACT_APP_OAUTH_AUTH_KEY}`

class AuthenticationService {
  private isLoggedIn = false

  private bearerToken = ''

  constructor() {
    this.isLoggedIn = false
  }

  isUserLoggedIn = (): boolean => {
    return this.isLoggedIn
  }

  setBearerToken = (token: string): void => {
    this.bearerToken = `Bearer ${token}`
  }

  getBearerToken = (): string => {
    return this.isUserLoggedIn() ? this.bearerToken : ''
  }

  signIn = async (loginUser: LoginUser): Promise<void> => {
    const formData = new FormData()
    formData.append('username', loginUser.username)
    formData.append('password', loginUser.password)
    formData.append('grant_type', 'password')

    await fetch(OAUTH_TOKEN_URL, {
      method: 'POST',
      body: formData,
      headers: { Authorization: OAUTH_AUTH_KEY },
    })
      .then((response: Response) => {
        if (response.status !== HttpStatus.OK) {
          throw new ApiResponseError(
            'HTTP POST authentication request failed.',
            response
          )
        }

        return response.json()
      })
      .then((jwtResponse: JwtResponse) => {
        this.setToken(jwtResponse)
      })
  }

  getCurrentUserDetails = (): JwtResponse => {
    const userDetails: string | null = localStorage.getItem(USER_DETAILS_KEY)
    return userDetails === null ? null : JSON.parse(userDetails)
  }

  logout = (): void => {
    localStorage.setItem(USER_DETAILS_KEY, '')
    this.isLoggedIn = false
    this.setBearerToken('')
  }

  refresh = async (refreshToken: string): Promise<void> => {
    const formData = new FormData()
    formData.append('refresh_token', refreshToken)
    formData.append('grant_type', 'refresh_token')

    await fetch(OAUTH_TOKEN_URL, {
      method: 'POST',
      body: formData,
      headers: { Authorization: OAUTH_AUTH_KEY },
    })
      .then((response: Response) => {
        if (response.status !== HttpStatus.OK) {
          throw new ApiResponseError(
            'HTTP POST authentication request failed.',
            response
          )
        }
        return response.json()
      })
      .then((jwtResponse: JwtResponse) => {
        this.setToken(jwtResponse)
      })
  }

  setToken = async (jwtResponse: JwtResponse) => {
    // TODO: sessionStorage vs localStorage vs navigator.credentials.store(new window.FederatedCredential)
    const now = new Date()
    const expiry = new Date(now.getTime() + jwtResponse.expires_in * 1000)
    const newJwtResponse: JwtResponse = jwtResponse
    newJwtResponse.expiry = expiry
    localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(newJwtResponse))
    this.isLoggedIn = true
  }
}

export default new AuthenticationService()
