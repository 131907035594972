import { Reducer } from 'redux'
import { UserActionTypes, UserState } from './types'
import JwtResponse from '../../models/JwtResponse'
import { userService } from '../../services/user-service'

export const initialState: UserState = {
  jwtResponse: undefined,
  showWelcome: false,
  errors: undefined,
  loading: false,
  loggedIn: false,
  hasAdminRole: false,
}

const reducer: Reducer<UserState> = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.SHOW_SETUP: {
      return {
        ...state,
        showWelcome: true,
      }
    }
    case UserActionTypes.DISMISS_SETUP: {
      return {
        ...state,
        data: {
          ...state.jwtResponse,
          showWelcome: false,
        },
      }
    }
    case UserActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case UserActionTypes.FETCH_SUCCESS: {
      const jsonWebTokenResponse = action.user as JwtResponse
      return {
        ...state,
        loading: false,
        loggedIn: true,
        jwtResponse: jsonWebTokenResponse,
        hasAdminRole: userService.isUserAnAdmin(
          jsonWebTokenResponse.access_token
        ),
      }
    }
    case UserActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    default: {
      return state
    }
  }
}

export { reducer as UserReducer }
