import Consumable from '../../models/Consumable'
import Page from '../../models/pagination/Page'

export enum ConsumableActionTypes {
  FETCH_ACTIVE_CONSUMABLE_REQUEST = '@@consumable/FETCH_CONSUMABLE_REQUEST',
  FETCH_ACTIVE_CONSUMABLE_SUCCESS = '@@consumable/FETCH_ACTIVE_CONSUMABLE_SUCCESS',
  FETCH_ACTIVE_CONSUMABLE_ERROR = '@@consumable/FETCH_ACTIVE_CONSUMABLE_ERROR',
  FETCH_DEPLETED_CONSUMABLE_REQUEST = '@@consumable/FETCH_DEPLETED_CONSUMABLE_REQUEST',
  FETCH_DEPLETED_CONSUMABLE_SUCCESS = '@@consumable/FETCH_DEPLETED_CONSUMABLE_SUCCESS',
  FETCH_DEPLETED_CONSUMABLE_ERROR = '@@consumable/FETCH_DEPLETED_CONSUMABLE_ERROR',
  DEPLETE_CONSUMABLE_REQUEST = '@@consumable/DEPLETE_CONSUMABLE_REQUEST',
  DEPLETE_CONSUMABLE_REQUEST_SUCCESS = '@@consumable/DEPLETE_CONSUMABLE_REQUEST_SUCCESS',
  DEPLETE_CONSUMABLE_REQUEST_ERROR = '@@consumable/DEPLETE_CONSUMABLE_REQUEST_ERROR',
  RESTORE_CONSUMABLE_REQUEST = '@@consumable/RESTORE_CONSUMABLE_REQUEST',
  RESTORE_CONSUMABLE_REQUEST_SUCCESS = '@@consumable/RESTORE_CONSUMABLE_REQUEST_SUCCESS',
  RESTORE_CONSUMABLE_REQUEST_ERROR = '@@consumable/RESTORE_CONSUMABLE_REQUEST_ERROR',
}

export interface ConsumableState {
  readonly loading: boolean
  readonly activeConsumablesPage: Page<Consumable>
  readonly depletedConsumablesPage: Page<Consumable>
  readonly errors?: string
}
