import { format } from 'date-fns'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Form, Field } from 'react-final-form'
import { openSnackBar } from 'store/snackbar/action'
import { SeverityType } from 'store/snackbar/types'
import { Button, Grid, Paper, TextField } from '@material-ui/core'

import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Consumable from '../../models/Consumable'
import UpdateConsumable from '../../models/UpdateConsumable'
import { httpGetRequest, httpPutRequest } from '../../utils/HttpUtil'

type PathParamsType = {
  id: string
}

type PropsType = RouteComponentProps<PathParamsType>

const API_URL = `${process.env.REACT_APP_API_URL}`

export default function InventoryItemUpdate(
  props: Readonly<PropsType>
): JSX.Element {
  const { match } = props
  const dispatch = useDispatch()
  const [consumable, setConsumable] = useState<UpdateConsumable>({
    id: Number(match.params.id),
  })
  const [, setTare] = useState<number>(0)
  const [expiration, setExpiration] = useState<string | undefined>()
  const [receiveDate, setReceiveDate] = useState<string | undefined>()

  // useRef to keep track of mounted state
  const isMounted = useRef(true)

  const memoizedCallback = useCallback(async () => {
    try {
      const response = await httpGetRequest<Consumable>(
        `${API_URL}/api/v1/consumables/${match.params.id}`
      )
      if (isMounted.current) {
        const { calculatedTare } = response.data
        setConsumable({ ...response.data })
        setTare(calculatedTare)
        setExpiration(response.data.expiration)
        setReceiveDate(response.data.receiveDate)
      }
    } catch (e) {
      console.error(`Could not fetch consumableId: ${match.params.id}`)
    }
  }, [match.params.id])

  useEffect(() => {
    memoizedCallback()

    // Cleanup function to set mounted state to false
    return () => {
      isMounted.current = false
    }
  }, [memoizedCallback])

  const handleClose = () => {
    const { history } = props
    history.goBack()
  }

  const handleExpirationDateChange = (value?: string | null) => {
    setExpiration(value ?? '')
  }

  const handleReceiveDateChange = (value?: string | null) => {
    setReceiveDate(value ?? '')
  }

  const handleFormSubmit = async (values: UpdateConsumable) => {
    const requestBody: UpdateConsumable = {
      ...values,
      receiveDate,
      expiration,
    }

    const { itemName, initialNetWeight } = requestBody

    if (itemName == null) {
      openSnackBar(dispatch, 'Item Name required!', SeverityType.ERROR)
      return
    }

    if (
      initialNetWeight &&
      !/^-?\d+(\.\d+)?$/.test(initialNetWeight.toString())
    ) {
      openSnackBar(
        dispatch,
        'Initial Content Weight must be a number.',
        SeverityType.ERROR
      )
      return
    }

    if (initialNetWeight && initialNetWeight <= 0) {
      openSnackBar(
        dispatch,
        'Initial Content Weight must be greater than zero.',
        SeverityType.ERROR
      )
      return
    }

    try {
      await httpPutRequest<UpdateConsumable>(
        `${API_URL}/api/v1/consumables`,
        requestBody
      )
    } catch (e) {
      console.error(e)
    } finally {
      handleClose()
    }
  }

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={consumable}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Paper style={{ padding: 16 }}>
            <h1>SST Form</h1>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Field name="itemName">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="ItemName"
                      fullWidth
                      margin="dense"
                      required
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="supplier">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Supplier"
                      fullWidth
                      margin="dense"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="casNumber">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="CAS Number"
                      fullWidth
                      margin="dense"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="productNumber">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Product Number"
                      fullWidth
                      margin="dense"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="lotNumber">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Lot Number"
                      fullWidth
                      margin="dense"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="location">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Storage Location"
                      fullWidth
                      margin="dense"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="initialNetWeight">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Initial Net Weight"
                      fullWidth
                      margin="dense"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="dense"
                    id="receiveDate"
                    label="ReceiveDate"
                    value={null}
                    inputValue={receiveDate}
                    onChange={(_, newValue) =>
                      handleReceiveDateChange(newValue)
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="dense"
                    id="expiration"
                    label="Expiration"
                    value={null}
                    inputValue={expiration}
                    minDate={new Date()}
                    minDateMessage={`Expiration date cannot be set anytime before '${format(
                      new Date(),
                      'yyyy-MM-dd'
                    )}'.`}
                    onChange={(_, newValue) =>
                      handleExpirationDateChange(newValue)
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    />
  )
}
