import { AxiosResponse } from 'axios'

export default class ApiAxiosResponseError extends Error {
  constructor(message: string, response: AxiosResponse) {
    super(message)
    this.response = response
  }

  response: AxiosResponse
}
