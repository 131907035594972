import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import CustomSnackBar from './components/custom/snackbar.component'
import HomeContainer from './components/home/index'
import SignInContainer from './components/signin/index'
import SignUpContainer from './components/signup/index'
import Welcome from './components/welcome/welcome.component'
import NotFound from './components/errors/404.component'
import ForgotPasswordContainer from './components/forgot-password/index'
import UpdatePasswordContainer from './components/update-password/index'
import PrivateRoute from './components/private-route/private-route'
import AuthenticationService from './services/auth-service'
import { UserActionTypes } from './store/user/types'

const Routes = (): JSX.Element => {
  const dispatch = useDispatch()
  const jwt = AuthenticationService.getCurrentUserDetails()

  if (jwt) {
    dispatch({ type: UserActionTypes.FETCH_SUCCESS, user: jwt })
  }

  return (
    <>
      <Switch>
        <Route path="/" component={Welcome} exact />
        <PrivateRoute path="/portal" component={HomeContainer} />
        <Route path="/user/signin" component={SignInContainer} exact />
        <Route path="/user/signup" component={SignUpContainer} exact />
        <Route
          path="/user/forgot-password"
          component={ForgotPasswordContainer}
          exact
        />
        <Route
          path="/user/update-password"
          component={UpdatePasswordContainer}
          exact
        />
        <Route component={NotFound} />
      </Switch>
      <CustomSnackBar />
    </>
  )
}

export default Routes
