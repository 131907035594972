import { createMuiTheme } from '@material-ui/core/styles'

const ScaledSolutionsPortalTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#04bcf4',
      dark: '#008cc1',
      light: '#69efff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#c3ebfc',
      dark: '#92b9c9',
      light: '#f6ffff',
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
      disabled: '#9e9e9e',
    },
  },
})

export default ScaledSolutionsPortalTheme
